<template>
  <SoftwareCenterModal
    :software="download"
    :open="open"
    @close-modal="closeModal()"
    @after-close-modal="afterCloseModal()"
  />
</template>

<script setup lang="ts">
import { useDownloadStore } from '~/stores/downloadStore/softwareDownloadStore';
import type {
  SoftwareByIdQueryModel,
  SoftwareByIdResponseModel
} from '~/server/api/downloads/software/byId.get';
import SoftwareCenterModal from '~/components/downloads/software/SoftwareCenterModal.vue';

const { $resolvedLocale } = useNuxtApp();
const logger = useLogger();
const toasts = useShowToast();

const route = useRoute();
const router = useRouter();
const store = useDownloadStore();

const open = ref(false);
const downloadId = computed<string | undefined>(
  () => route.hash?.split('software=')?.[1]
);

// XXX: forward loading state?
const { data: download, error } = await useAsyncData(
  async () => {
    if (downloadId.value) {
      const cachedDownload = Object.values(
        store.softwareDownloads?.results ?? []
      ).find((hit) => hit.id === downloadId.value);

      return (
        cachedDownload ??
        (await $fetch<SoftwareByIdResponseModel>(
          '/api/downloads/software/byId',
          {
            method: 'get',
            query: {
              id: downloadId.value,
              locale: $resolvedLocale.value!
            } satisfies SoftwareByIdQueryModel
          }
        ))
      );
    }

    return null;
  },
  {
    deep: false,
    lazy: true,
    watch: [downloadId]
  }
);

watch(error, () => {
  if (error.value) {
    toasts.showToast(
      `Failed to display software modal for download with id ${downloadId.value}`
    );

    logger.error(
      `error during software fetch in document center standalone modal for id ${downloadId.value}`,
      error.value
    );
  }
});

watch(download, () => {
  // only open download if download id is given
  // download can actually be `null` (e.g. for 404 or other errors).
  if (downloadId.value) {
    open.value = true;
  }
});

function closeModal() {
  open.value = false;
}

function afterCloseModal() {
  // closeModal is not triggered while closing the modal via esc...
  // so we also have to set the open value here.
  open.value = false;

  router.push({
    hash: undefined,
    query: route.query
  });
}
</script>
